import React from 'react';
import styled from 'styled-components';
import spiner from '../images/giphy.gif';
import { Metrics } from '../theme/index'

const LoadingContainer = styled.div`
  text-align: center;
  margin: 1vmin;
  @media (max-width:767px) {
  margin: 10vmin;
  }
`
const Spiner = styled.img`
  height: ${Metrics.loading}px;
  width: ${Metrics.loading}px;
`

function Loading() {

  return (
    <LoadingContainer>
      <Spiner src={spiner} data-testid="loading" />
    </LoadingContainer>
  )
}

export default Loading;
