import React from 'react';
import styled from 'styled-components';
// import loguito from '../images/logo.png';

const View = styled.div`
  width: 100%;
  display: flex;
  flex-direction : column;
  align-items     : flex-start;
  justify-content : flex-start;

  @media (max-width:767px) {
    flex-direction : row;
    margin-top: 1vmax;
}
`
// const Logo = styled.img`
//   max-height: 10vmin;
//   margin: 5vmin;
//   align-self: flex-start;
//   cursor:pointer;
// `
const Name = styled.h1`
  align-self: flex-start;
  margin-left: 4vmax;
  display: inline;
  color: black;
  font-size: 28px;
  cursor:pointer;
  font-family: 'CormorantRegular';
  @media (max-width:767px) {
    margin: auto;
  }
`
const Button = styled.div`
  align-self: flex-start;
  margin-left: 4.2vmax;
  display: inline;
  color: black;
  cursor:pointer;
  font-family: 'RobotoMedium';
  @media (max-width:767px) {
    margin: auto;
  }
`

function TopNav({ navigateTo }) {

  return (
    <View>
      {/* <Logo src={loguito} onClick={() => navigateTo("")} className="App-logito" alt="logo" /> */}
      <Name onClick={() => navigateTo("")} >Beatriz Conca</Name>
      <Button type="button" onClick={() => navigateTo("work")}>Work</Button>
      {/* <Button type="button" onClick={() => navigateTo("editorial")}>Editorial</Button> */}
      <Button type="button" onClick={() => navigateTo("info")}>Info</Button>
    </View>
  )
}

export default TopNav;
