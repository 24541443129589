import React from 'react';
import styled from 'styled-components';

import './info.scss';
import instagramn from '../images/instagram-logo.png';

const Logo = styled.img`
  height: 24px;
  width: 24px;
  margin: 1vmax;
  margin-bottom: 10vmax;
  align-self: center;
  cursor:pointer;
  @media (max-width:767px) {
    height: 32px;
    width: 32px;
}
`

function Info() {

    return (
        <>
            <div className="Info-container">
                <p>
                    Based in Spain.
                    <br />
                    <br />
                    Email for enquiries
                </p>
                <h3>info@beatrizconca.com</h3>
                <Logo src={instagramn} onClick={() => window.open('https://www.instagram.com/beatrizconca/', '_blank')} className="App-logito" alt="logo" />
            </div>
        </>
    )
}

export default Info;
