import React from 'react';
import styled from 'styled-components';

import './work.scss';

const Item = styled.div`
  max-height: 50vmax;
  margin-bottom: 3vmax;
  width: 100%;
  @media (max-width:767px) {
    max-height: 70vmax;
}
`
const ImageSingle = styled.img`
  max-height: 50vmax;
  padding: 10px;
  @media (max-width:767px) {
    max-height: 70vmax;
    padding: 0;
}
`
const Pair = styled.div`
  max-height: 40vmax;
  margin-bottom: 3vmax;
  width: 80%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;

  @media (max-width:767px) {
    max-height: 35vmax;
    width: 100%;
}
`
const ImagePaired = styled.img`
  max-height: 40vmax;
  padding: 10px;
  @media (max-width:767px) {
    max-height: 35vmax;
        width: 45%;
    flex: 0.5;
    padding: 2%;
}
`

function Work() {

    const images = [
        { url1: "images/work/work26.jpg", url2: "images/work/work20.jpg", id: 0, pair: true },
        { url: "images/work/work21.jpg", id: 2, pair: false },
        { url: "images/work/work22.jpg", id: 3, pair: false },
        { url: "images/work/work23.jpg", id: 4, pair: false },
        { url: "images/work/work3.jpg", id: 5, pair: false },
        { url1: "images/work/work24.jpg", url2: "images/work/work25.jpg", id: 6, pair: true },
        { url: "images/work/work28.jpg", id: 8, pair: false },
        { url: "images/work/work10.jpg", id: 9, pair: false },
        { url: "images/work/work15.jpg", id: 10, pair: false },
        { url: "images/work/work9.jpg", id: 11, pair: false },
        { url1: "images/work/work27.jpg", url2: "images/work/work13.jpg", id: 12, pair: true },
        { url: "images/work/work17.jpg", id: 14, pair: false },
        { url: "images/work/work5.jpg", id: 15, pair: false },
        { url: "images/work/work2.jpg", id: 16, pair: false },
        { url1: "images/work/work6.jpg", url2: "images/work/work29.jpg", id: 17, pair: true },
    ];

    return (
        <>
            <div className="Work-container">
                {images.map(item => {
                    return item.pair ?
                        <Pair key={item.id}>
                            <ImagePaired src={item.url1} alt="Beatriz Conca Photographer" />
                            <ImagePaired src={item.url2} alt="Beatriz Conca Photographer" />
                        </Pair>
                        :
                        <Item key={item.id}>
                            <ImageSingle src={item.url} alt="Beatriz Conca Photographer" />
                        </Item>
                }
                )
                }
            </div>
        </>
    )
}

export default Work;
