const metrics = {
    buttonSize: 48,
    buttonRadius: 24,
    nanoSpace: 2,
    miniSpace: 4,
    smallSpace: 8,
    baseSpace: 16,
    plusSpace: 24,
    doubleSpace: 32,
    tripleSpace: 48,
    bigSpace: 64,
    giantSpace: 96,
    shadowOpacity: 0.3,
    borderRadius: 8,
    borderRadiusBig: 16,
    loading: 72,
    icons: {
        small: 16,
        medium: 24,
        big: 48,
        giant: 96,
    },
    images: {
        imageMini: 64,
        image: 96,
        imageBig: 256
    },
    fonts: {
        h1: 24,
        h2: 20,
        body1: 16,
        body2: 14,
        small: 12,
        mini: 10,
    }
};

export default metrics;
