import React from 'react';

import Slider from '../components/slider'
import './home.scss';

function Home() {

    return (
        <>
            <div className="Home-container">
                <Slider />
            </div>
        </>
    )
}

export default Home;
