import React from 'react';
import styled from 'styled-components';
import { Metrics } from '../theme/index'

const SliderContainer = styled.div`
  margin: 1vmin;
`
const Item = styled.img`
  max-height: 40vmax;
  margin-bottom: 2vmax;
  display: flex;
  align-self: center;
  cursor: pointer;
  @media (max-width:767px) {
    max-height: 60vmax;
}
`

const images = [
  { url: "images/slider/slide6.jpg" },
  { url: "images/slider/slide1.jpg" },
  { url: "images/slider/slide2.jpg" },
  { url: "images/slider/slide3.jpg" },
  { url: "images/slider/slide7.jpg" },
  { url: "images/slider/slide4.jpg" },
  { url: "images/slider/slide5.jpg" },
];

class Slider extends React.Component {
  constructor() {
    super();
    this.state = { image: images[0].url, index: 0 };
  }

  componentDidMount() {
    this.startSlider();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  startSlider() {
    if (this.timer) clearInterval(this.timer);
    this.timer = setInterval(this.changeImg, 3000);
  }

  changeImg = () => {
    const { index } = this.state;
    let newIndex = 0;

    (index < (images.length - 1)) ? newIndex = index + 1 : newIndex = 0;
    console.log('Image:' + newIndex);

    this.setState({ image: images[newIndex].url, index: newIndex });

    this.startSlider();
  }

  render() {
    const { image } = this.state;

    return (
      <SliderContainer data-testid="photo-slider">
        <Item src={image} onClick={this.changeImg} alt="Beatriz Conca Photographer" />
      </SliderContainer>
    )
  }
}

export default Slider;
