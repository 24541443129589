import React from 'react';
import './App.scss';
import TopNav from './components/topnav';
import Home from "./views/home";
import Info from "./views/info";
import Work from "./views/work";
import Editorial from "./views/editorial";

class App extends React.Component {
  constructor() {
    super();
    this.state = { page: null };
  }

  changePage = page => {
    this.setState({ page });
  }

  render() {
    const { page } = this.state;
    if (page === "work") return (
      <div className="App" >
        <header className="App-header">
          <TopNav navigateTo={this.changePage} />
        </header>
        <div className="Work-container">
          <Work />
        </div>
      </div>
    );
    if (page === "editorial") return (
      <div className="App" >
        <header className="App-header">
          <TopNav navigateTo={this.changePage} />
        </header>
        <div className="Editorial-container">
          <Editorial />
        </div>
      </div>
    );
    if (page === "info") return (
      <div className="App" >
        <header className="App-header">
          <TopNav navigateTo={this.changePage} />
        </header>
        <div className="Info-container">
          <Info />
        </div>
      </div>
    );

    if (!page) return (
      <div className="App">
        <header className="App-header">
          <TopNav navigateTo={this.changePage} />
        </header>
        <section>
          <Home />
        </section>
      </div>
    );
  }
}

export default App;
