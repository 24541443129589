import React from 'react';
import styled from 'styled-components';

import Loading from '../components/loading'
import './editorial.scss';

const Item = styled.img`
  max-height: 40vmax;
  margin: 2vmax;
  align-self: center;
  @media (max-width:767px) {
    max-height: 60vmax;
}
`

function Editorial() {

    const images = [
        { url: "images/editorial/editorial.jpg" },
    ];

    return (
        <>
            <div className="Editorial-container">
                {images.map(item =>
                    <Item src={item.url} />
                )
                }
            </div>
        </>
    )
}

export default Editorial;
